import { Injectable, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { ProgramDataService } from 'src/app/core/services/program-data.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { gql } from 'apollo-angular';
import { ContentfulService } from './contentful.service';
import { Constants } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class StartDatesService {

  startDateTermConfiguration = new BehaviorSubject<StartDateTermData[] | false>(false);
  defaultStartDateTermData: StartDateTermData[] = [];

  startDateTermDataQuery = gql`query siteConfiguration($ID: String!){
      siteConfiguration(id: $ID) {
        startDateTermConfigCollection(limit:5){
          items{
            from,
            to,
            term
          }
        }
      }
    }`


  constructor(private commonService: CommonService,
    private programDataService: ProgramDataService,
    private contentful: ContentfulService,
    private constants: Constants) { }

  /**
  * To fetch the default start date terms data. 
  */
  getDefaultStartDateTermData() {
    this.contentful.getPageData(this.startDateTermDataQuery, { ID: this.constants.DefaultSiteConfigEntry }).subscribe(res => {
      try {
        this.defaultStartDateTermData = res.data.siteConfiguration.startDateTermConfigCollection.items.length == 0
          ? this.getDefaultStartDateTermConfig()
          : res.data.siteConfiguration.startDateTermConfigCollection.items;

        this.startDateTermConfiguration.next(this.defaultStartDateTermData);
      } catch (e) {
        console.error("Error while extracting data from default site configuration content", e);
      }
    });
  }

  getDefaultStartDateTermConfig() {
   
    return [
      {
        from: '2025-01-01T00:00:00.000Z',
        to: '2025-03-02T00:00:00.000Z',
        term: 'Spring 2025 Session I'
      },
      {
        from:  '2025-03-03T00:00:00.000Z',
        to: '2025-04-27T00:00:00.000Z',
        term: 'Spring 2025 Session II'
      },
      {
        from:  '2025-04-28T00:00:00.000Z',
        to:  '2025-06-22T00:00:00.000Z',
        term: 'Summer 2025 Session I'
      },
      {
        from: '2025-06-22T00:00:00.000Z',
        to: '2025-08-17T00:00:00.000Z',
        term: 'Summer 2025 Session II'
      }
    ];
  }

  /**
   * To format the values from the DB to match the contentful data structure.
   * @param startDates start dates from the DB
   */
  formatStartDateItems(startDates: any) {
    const formattedStartDates: any[] = [];
    startDates.forEach((item: any) => {
      if (item?.startDate?.length > 0) {
        formattedStartDates.push({ __typename: 'StartDateItems', calendarDate: item?.startDate, startDateDescriptor: item?.startDateDescriptor, applyByDate: item?.applyByDate });
      }
    });

    return formattedStartDates;
  }

  /**
   * To format the values from the DB to match the contentful data structure.
   * @param startDates start dates from the DB
   * @param startDateTermConfig default start dates from the CMS
   */
  formatStartDateItemsTermsOnly(startDates: any, startDateTermConfig: any) {
    const terms: any[] = [];
    const currentDate = Date.now();

    startDates.forEach((item: any) => {
      const date = new Date(item.startDate.replace(/-/g, '\/').replace(/T.+/, ''));

      //check if item.applyBydate is not null 
      const applyByDate = item.applyByDate ? new Date(item.applyByDate) : null;
      const weekFuture = currentDate + 1000 * 60 * 60 * 24 * 14;

      if ((applyByDate != null && applyByDate.getTime() > currentDate) || date.getTime() > weekFuture) {
        terms.push(this.getTermForDate(date, startDateTermConfig));
      }
    });

    return terms;
   
  }

  getTermForDate(date: Date, config: any[]): string {
    // Ensure the input date is a Date object
    const inputDateInMilliseconds =date.getTime();
    for (const termConfig of config) {
      const fromDate = parseDateWithoutTimezone(termConfig.from);
      const toDate = parseDateWithoutTimezone(termConfig.to);
      const fromDateInMilliseconds = fromDate.getTime();
      const toDateInMilliseconds = toDate.getTime();
      
      if (fromDateInMilliseconds <= toDateInMilliseconds && (inputDateInMilliseconds >= fromDateInMilliseconds && inputDateInMilliseconds <= toDateInMilliseconds)) {
        return termConfig.term;
      }
    }

    return '';
  }  

}

export interface StartDateTermData {
  from: string,
  to: string,
  term: string
}

export class StartDateItem {
  date: Date;
  monthName = '';
  dateDescription = '';
  applyBydate = '';
  termName = '';

  constructor(date: Date, dateDescription: string, startDateTermConfig: any, applyByData: Date | null = null) {
    this.date = date;
    this.monthName = this.getMonthName(date);
    this.dateDescription = dateDescription;
    this.termName = this.getTermForDate(date, startDateTermConfig);
    this.applyBydate = this.getApplyByDate(applyByData)
  }

  getMonthName(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()];
  }


  getTermForDate(date: Date, config: any[]): string {
    const inputDateInMilliseconds =date.getTime();
    for (const termConfig of config) {
      const fromDate = parseDateWithoutTimezone(termConfig.from);
      const toDate = parseDateWithoutTimezone(termConfig.to);
      const fromDateInMilliseconds = fromDate.getTime();
      const toDateInMilliseconds = toDate.getTime();
      
      if (fromDateInMilliseconds <= toDateInMilliseconds && (inputDateInMilliseconds >= fromDateInMilliseconds && inputDateInMilliseconds <= toDateInMilliseconds)) {
        return termConfig.term;
      }
    }

    return '';
  }  

  getApplyByDate(date: Date | null): string {
    const months = ["Jan.", "Feb.", "March", "April", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."]

    if (date == null) {
      return ''
    }

    //get the month and date 
    const month = date.getMonth()
    const day = date.getDate()
    return `Apply By: ${months[month]} ${day}`

  }
}

function parseDateWithoutTimezone(input: string): Date {
  let val = input.split('T')[0];
  let date = new Date(`${val}T00:00:00`);
  return date;
}
