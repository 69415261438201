import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';
import { CommonService } from 'src/app/core/services/common.service';
import { HeaderData, HeaderFooterService } from 'src/app/core/services/header-footer.service';
import { StartDatesService } from 'src/app/core/services/start-dates.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit{

  headerData: HeaderData = {} as HeaderData;
  DefaultHeaderData: HeaderData = {} as HeaderData;
  isContactUsOpen = false;
  isMobile = false;
  isChatInitialized = false;
  isChatLoaded = false;

  constructor(
    private headerService: HeaderFooterService,
    private commonService: CommonService,
    private chatService: ChatService,
    private startDatesService: StartDatesService
  ) {}

  ngOnInit(): void {
    this.startDatesService.getDefaultStartDateTermData()
    this.headerService.getDefaultSiteConfiguration();
    // Listen for header configuration changes.
    this.headerService.headerConfiguration.subscribe((headerData: HeaderData | false) => {
      // if value 'false' is passed then use the defaultHeaderData from the service. This used for the override purpose.
      if (headerData) {
        this.headerData = headerData;
      } else {
        this.headerData = this.headerService.defaultHeaderData;
      }
      // Initialize chat widget
      this.initChat();

    });

    this.chatService.chatWidgetLoaded$.subscribe(data => {
      console.log("chat loaded");
      this.isChatLoaded = data;
    })

    if(this.commonService.isPlatformBrowser()) {
      // Listen for mobile resolution activation
      this.commonService.sm$.subscribe(isMobile => {
        console.log("is mobile ", isMobile);
        this.isMobile = isMobile;
      })
    }

  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (!event.target.closest('.contact-us')) {
      this.isContactUsOpen = false;
    }
  }

  openContactUs() {
    if (this.isContactUsOpen) {
      this.isContactUsOpen = false
    } else {
      this.isContactUsOpen =  true
    }
  }

  triggerChat() {
    this.chatService.chatButtonClick.next(true);
  }

  /**
   * To initialize the genesys chat widget
   */
  initChat() {
    // TIODO - ask business to add a check box to control the enable/disable of chat.
    // check if any value present on chat button field and initialize the chat
    if(!this.isChatInitialized && this.commonService.isPlatformBrowser()) {
      this.chatService.addGenesysChatWidget();
      this.isChatInitialized = true;
    }
  }
}
